<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="danger"
      @click="toggleModal()"
    >
      <i class="uil uil-minus-circle mr-1"></i>
      {{ $t('credits.direct_withdraw')}}
    </b-button>
    <b-modal
      v-model="isWithdrawModalActive"
      :title="`${$t('credits.direct_withdraw')}`"
      hide-footer
    >
      <ValidationObserver
        ref="withdrawForm"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('credits.credit_amount')"
            rules="required|min_value:1"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('credits.credit_amount')} *`"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="transferForm.amount"
                type="number"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('fields.remark')} *`"
              label-for="detail"
            >
              <b-form-textarea
                id="detail"
                v-model="transferForm.detail"
                :state="errors[0] ? false : null"
              ></b-form-textarea>
            </b-form-group>

          </ValidationProvider>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel')}}
            </b-button>
            <b-overlay
              :show="isLoading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm')}}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    agentId: {
      type: String,
      default: '',
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isWithdrawModalActive: false,
      transferForm: {
        amount: 0,
        detail: '',
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.agent.isWithdrawingAgentCredit
    }),
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['withdrawAgentCredit']),
    setData() {
      this.transferForm = {
        amount: 0,
        detail: '',
      }
    },
    toggleModal() {
      this.isWithdrawModalActive = !this.isWithdrawModalActive
    },
    onCloseModal() {
      this.isWithdrawModalActive = false
      this.setData()
    },
    async onSubmit() {
      this.transferForm.amount = +this.transferForm.amount
      await this.withdrawAgentCredit({
        agentId: this.agentId,
        data: this.transferForm,
      })
      this.onCloseModal()
    },
  },
}
</script>